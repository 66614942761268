import { within } from 'ranged-overflow'

function boundries(map, x, y) {
    return within(0, map.length - 1, x) && within(0, map[0].length - 1, y)
}

function set(map, x, y, value) {
    if (boundries(map, x, y)) {
        map[x][y] = value
    }
}

function get(map, x, y) {
    if (boundries(map, x, y)) {
        return map[x][y]
    }
}

export class Scene {
    constructor(height, width, cellsize) {
        this.map = []
        for (let x = 0; x < width / cellsize; x++)
            this.map[x] = new Uint8ClampedArray(height / cellsize)

        this.width = this.map.length
        this.height = this.map[0].length
    }

    live(x, y) {
        set(this.map, x, y, 1)
        this.shoulddraw = true
    }

    die(x, y) {
        set(this.map, x, y, 0)
        this.shoulddraw = true
    }

    alive(x, y) {
        return get(this.map, x, y) === 1
    }

    dirty() {
        let was = this.shoulddraw
        this.shoulddraw = false
        return was
    }

    everyCell(perdicate) {
        this.map.forEach((column, x) =>
            column.forEach((cell, y) => perdicate(x, y, cell === 1))
        )
    }
}
