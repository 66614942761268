<style>
.controls {
    top: 0.3em;
    left: 0.3em;
    padding: 0.6em;
    position: absolute;
    background: white;
    border-radius: var(--border-radius);
    border: 1px solid var(--border-color);
    background-color: var(--container);
    color: var(--text);
}

@media screen and (min-width: 15rem) and (max-width: 25rem) {
    .controls-open {
        left: 0em;
        right: 0em;
    }
}

@media screen and (max-width: 15rem) {
    .controls-open {
        left: 0em;
    }
}

.controls-closed {
    border: 0;
    background-color: var(--transparent);
    opacity: 0.5;
    transition: opacity 0.2s;
}

.controls-closed:hover {
    opacity: 1;
}

.controls-highlight {
    background-color: var(--highlight);
    border: 1px solid var(--border-color);
    transition: opacity 1.2s;
}

.controls-highlight-attention {
    opacity: 0.9;
}

.control {
    background: var(--controls);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    margin: 0.3em;
    padding: 1.2em;
}

#buttons {
    display: flex;
    justify-content: space-between;
}
</style>

<main>
    {#if ready}
        <div
            class="controls {open
                ? 'controls-open'
                : 'controls-closed'}
                {attention
                ? 'controls-highlight-attention controls-highlight'
                : ''}"
            in:scale="{{ duration: 500 }}">
            <div id="buttons" class="control">
                <Control
                    on:click="{(event) => (open = event.detail.open)}"
                    bind:open />

                {#if open}
                    <div in:scale="{{ duration: controlsScale }}">
                        <Reset on:click="{reset}" />
                    </div>
                    <div in:scale="{{ duration: controlsScale }}">
                        <PlayPause
                            bind:pause
                            on:click="{(event) =>
                                (pause = event.detail.pause)}" />
                    </div>
                    <div in:scale="{{ duration: controlsScale }}">
                        <Draw
                            bind:drawing
                            on:click="{(event) =>
                                (drawing = event.detail.drawing)}" />
                    </div>
                {/if}
            </div>
            {#if open}
                <div class="control" in:scale="{{ duration: controlsScale }}">
                    <Speed
                        speed="{speed}"
                        on:change="{(event) => (speed = event.detail.speed)}" />
                </div>
            {/if}
        </div>
    {/if}
</main>

<script>
import { createEventDispatcher } from 'svelte'
import { scale } from 'svelte/transition'

import PlayPause from './PlayPause.svelte'
import Control from './Control.svelte'
import Reset from './Reset.svelte'
import Speed from './Speed.svelte'
import Draw from './Draw.svelte'

const dispatch = createEventDispatcher()

export let speed = 275 // milliseconds
export let pause = false
export let drawing = false

let controlsScale = 100 // milliseconds
let open = false
let ready = false
let attention = false

function reset() {
    dispatch('reset')
}

$: dispatch('pause', {
    pause: pause,
})

$: dispatch('drawing', {
    drawing: drawing,
})

$: dispatch('speed', {
    speed: speed,
})

setTimeout(() => {
    ready = true
    attention = true
    setTimeout(() => (attention = false), 2000)
}, 500)
</script>
