import { overflow } from 'ranged-overflow'

export function neighbours(get, x, y, height, width) {
    if (height < 3 || width < 3)
        throw RangeError(
            'To find all neighbours, the array must be of at least size 3 * 3'
        )

    const col = (pos) => overflow(0, width - 1, pos)
    const row = (pos) => overflow(0, height - 1, pos)

    const up = -1
    const down = +1
    const left = -1
    const right = +1

    return [
        get(col(x + left), row(y + up)),
        get(col(x), row(y + up)),
        get(col(x + right), row(y + up)),

        get(col(x + left), row(y)),
        get(col(x + right), row(y)),

        get(col(x + left), row(y + down)),
        get(col(x), row(y + down)),
        get(col(x + right), row(y + down)),
    ]
}
