<main>
    <button aria-label="{text}" on:click="{click}" tabindex="0">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 256"
            aria-labelledby="title-controls">
            <title id="title-controls">Image to symbol controls</title>
            <circle cx="55.996" cy="136" r="20" opacity=".2"></circle>
            <path
                d="M135.996 61.17V40a8 8 0 0 0-16 0v21.17a27.995 27.995 0 0 0 0 53.66V216a8 8 0 0 0 16 0V114.83a27.995 27.995 0 0 0 0-53.66zm-8 38.83a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12zm100 68a28.045 28.045 0 0 0-20-26.83L207.997 40a8 8 0 1 0-16 0v101.17a27.995 27.995 0 0 0 0 53.66V216a8 8 0 0 0 16 0v-21.17a28.045 28.045 0 0 0 20-26.83zm-28 12a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12zm-136-70.83V40a8 8 0 0 0-16 0v69.17a27.995 27.995 0 0 0 0 53.66V216a8 8 0 1 0 16 0v-53.17a27.995 27.995 0 0 0 0-53.66zm-8 38.83a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12z">
            </path>
        </svg>
    </button>
</main>

<script>
import { createEventDispatcher } from 'svelte'

const dispatch = createEventDispatcher()

export let open = true
let text

function updateText() {
    if (open) {
        text = 'Close controls'
    } else {
        text = 'Open controls'
    }
}

updateText()

function click() {
    open = !open
    updateText()
    dispatch('click', {
        open: open,
    })
}
</script>
