<style>
main {
    display: flex;
    justify-content: space-between;
    background-color: var(--action);
    border-radius: var(--border-radius);
    padding: 0.4rem;
    border: 1px solid var(--border-color);
}

main input {
    direction: rtl;
    width: 60%;
}

main svg {
    height: 3rem;
    stroke: var(--text);
}
</style>

<main>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        aria-labelledby="title-controls">
        <title id="title-controls">Slow as turtle</title>
        <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
            <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-width="2.378">
                <path
                    d="M40.26 44.23s8.323 0 10.7-1.189c2.378-1.189 4.236.818 4.236.818.669-1.562-.669-3.197-.669-3.197 4.756 2.379 7.134 0 7.134 0 3.567 0 3.567-14.27-5.945-15.46s-9.512 10.7-9.512 10.7l-2.378 1.19s0 2.378-3.567 7.134z">
                </path>
                <path
                    d="M43.83 35.9s-10.7-10.7-22.59-5.945c-11.89 4.756-10.7 16.65-10.7 16.65s8.323 5.945 19.02 2.378 12.04-6.688 12.04-6.688 2.229-1.635 2.229-6.391">
                </path>
                <path d="M11.72 42.4s17.15 10.5 29.73-7.688"> </path>
                <path
                    d="M18.86 44.23s3.567-5.202-4.756-8.323m5.94 3.561 9.44-2.949 1.261-6.563M34.02 41.25l-4.46-4.16M16.67 50.25l-8.529 3.043s4.525 3.856 9.346 2.964c4.821-.891 5.116-5.638 5.116-5.638l-5.934-.37M33.05 47.65l3.641 7.283s5.945 1.189 9.512 0-6.391-9.661-9.141-8.992">
                </path>
            </g>
        </svg>
    </svg>

    <input
        aria-label="Set speed of the game, you can use the arrow keys."
        type="range"
        min="{milliSecondsInSlider(min)}"
        max="{milliSecondsInSlider(max)}"
        bind:value
        on:keydown="{key}" />

    <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
        aria-labelledby="title-controls">
        <title id="title-controls">Fast as rocket</title>

        <g
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-width="2.378">
            <path
                d="M48.405 29.49c-3.276 0-5.941-2.664-5.941-5.94s2.665-5.938 5.941-5.938 5.941 2.664 5.941 5.939-2.665 5.939-5.941 5.939zm0-9.899a3.964 3.964 0 0 0-3.96 3.96 3.964 3.964 0 0 0 3.96 3.96 3.964 3.964 0 0 0 3.96-3.96 3.964 3.964 0 0 0-3.96-3.96z"
            ></path>
            <path
                d="M20.653 45.063a14.377 14.377 0 0 0-4.533 3.05c-3.158 3.164-4.652 7.56-3.97 11.688 4.128.676 8.522-.82 11.683-3.977a14.374 14.374 0 0 0 3.053-4.532M14.923 35.749c-.69.65-1.347 1.33-1.97 2.038a29.864 29.864 0 0 1 9.761 4.679c.227-.414.51-.794.844-1.128a4.988 4.988 0 0 1 2.542-1.36c.21-4.05 1.336-8.09 3.292-11.814l.006-.011a30.762 30.762 0 0 0-7.64 2.743 30.746 30.746 0 0 0-6.835 4.853h0zm17.05 10.09a4.985 4.985 0 0 1-1.362 2.55 4.986 4.986 0 0 1-1.145.852 29.857 29.857 0 0 1 4.695 9.75c.71-.62 1.391-1.278 2.042-1.968a30.742 30.742 0 0 0 4.852-6.832 30.743 30.743 0 0 0 2.744-7.636c-3.729 1.955-7.772 3.077-11.826 3.284h0z"
            ></path>
            <path
                d="M31.83 43.345c.27.886.25 1.692.137 2.525 7.076-.362 13.993-3.504 19.243-8.742 6.71-6.721 9.884-16.063 8.435-24.834-8.771-1.436-18.108 1.742-24.823 8.451-5.232 5.251-8.368 12.164-8.724 19.234a4.979 4.979 0 0 1 2.77.239"
            ></path>
            <path
                d="M37.072 34.196h0a30.755 30.755 0 0 0-6.7 4.584 30.743 30.743 0 0 0-5.384 6.421 29.987 29.987 0 0 0-1.392 2.47"
            ></path>
        </g>
    </svg>
</main>

<script>
import { createEventDispatcher } from 'svelte'
import { clamp } from 'ranged-overflow'

const dispatch = createEventDispatcher()

function sliderInMilliSeconds(speed) {
    return Math.floor(clamp(min, max, speed ** 3 / 1000000))
}

function milliSecondsInSlider(value) {
    return Math.floor(Math.cbrt(value * 1000000))
}

function key(event) {
    if (event.code == 'ArrowLeft') value += 20
    if (event.code == 'ArrowRight') value -= 20
}

export let speed = 275
export let min = 27
export let max = 1000

let value = milliSecondsInSlider(speed)

$: dispatch('change', {
    speed: sliderInMilliSeconds(value),
})
</script>
