<style>
main {
    height: 100%;
}

#size {
    height: 100%;
    width: 100%;
}
</style>

<main>
    {#if !drawing}
        <div class="gitlab">
            <Gitlab url="{url}" bind:corner />
        </div>
    {/if}
    <div id="size">
        <canvas
            id="game"
            aria-label="Area where the cells from the game are drawn"
            on:mousemove|nonpassive="{(event) =>
                (event.buttons === 1 ||
                    event.buttons === 3 ||
                    event.buttons === 5) &&
                draw(
                    clamp(
                        0,
                        event.target.width - 1,
                        event.x - event.target.offsetLeft
                    ),
                    clamp(
                        0,
                        event.target.height - 1,
                        event.y - event.target.offsetTop
                    )
                )}"
            on:touchmove|nonpassive|preventDefault="{(event) =>
                draw(
                    clamp(
                        0,
                        event.touches[0].target.width - 1,
                        event.touches[0].clientX - event.target.offsetLeft
                    ),
                    clamp(
                        0,
                        event.touches[0].target.height - 1,
                        event.touches[0].clientY - event.target.offsetTop
                    )
                )}"></canvas>
    </div>
    <Controls
        on:reset="{reset}"
        bind:pause
        on:pause="{(event) => (pause = event.detail.pause)}"
        bind:drawing
        on:drawing="{(event) => (drawing = event.detail.drawing)}"
        bind:speed
        on:speed="{(event) => (speed = event.detail.speed)}" />
</main>

<script>
import { onMount } from 'svelte'

import Gitlab from './Gitlab.svelte'

import { Scene } from './Scene'
import { neighbours } from './neighbours'
import { overflow, clamp } from 'ranged-overflow'

import Controls from './Controls.svelte'

const tick = 20

let speed = 275
let pause = false
let drawing = false
let url = 'https://gitlab.com/thro/conway/'
let corner = 'top-right'

let reset = () => {}
let draw = () => {}

function resize() {
    if (window.innerWidth <= 530) corner = 'bottom-right'
    else corner = 'top-right'
}
window.addEventListener('resize', resize)

function start() {
    function random() {
        let scene = new Scene(canvas.height, canvas.width, cellsize)
        for (let i = 0; i < (scene.width * scene.height) / 2; i++)
            scene.live(
                Math.floor(Math.random() * scene.width),
                Math.floor(Math.random() * scene.height)
            )

        return scene
    }

    function reset() {
        resize()
        current = random()
    }

    function draw(x, y) {
        if (drawing) {
            current.live(Math.floor(x / cellsize), Math.floor(y / cellsize))
        }
    }

    function resize() {
        let size = document.getElementById('size')

        canvas.height = size.clientHeight - 4
        canvas.width = size.clientWidth
    }

    function step() {
        let next = new Scene(canvas.height, canvas.width, cellsize)

        current.everyCell((x, y, alive) => {
            let found = neighbours(
                (x, y) => current.alive(x, y),
                x,
                y,
                current.height,
                current.width
            ).reduce((accumulator, element) => (accumulator += element))
            if (alive && found >= 2 && found <= 3) next.live(x, y)
            else if (found === 3) next.live(x, y)
            else next.die(x, y)
        })

        current = next
    }

    window.addEventListener('resize', resize)

    const cellsize = 4
    const canvas = document.getElementById('game')
    const ctx = canvas.getContext('2d')

    ctx.x = (pos) => overflow(0, canvas.width, pos * cellsize)
    ctx.y = (pos) => overflow(0, canvas.height, pos * cellsize)

    let current = random()

    let lastStep = performance.now()
    let lastDraw = performance.now()
    setInterval(() => {
        if (!pause && performance.now() - lastStep > speed) {
            step()
            lastStep = performance.now()
        }
        if (current.dirty() || performance.now() - lastDraw > 100) {
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            ctx.fillStyle = '#000'
            current.everyCell(
                (x, y, alive) =>
                    alive &&
                    ctx.fillRect(ctx.x(x), ctx.y(y), cellsize, cellsize)
            )
            lastDraw = performance.now()
        }
    }, tick)

    setTimeout(() => {
        reset()
    }, tick)

    return [reset, draw]
}

onMount(() => {
    // eslint-disable-next-line no-extra-semi
    ;[reset, draw] = start()
})
</script>
