<style>
.gitlab {
    position: absolute;
    display: flex;
    justify-content: center;
    background-color: #5487;
    height: 200px;
    width: 200px;
    top: -100px;
    right: -100px;
    border-radius: 15px;
    transform: rotate(45deg);
    color: black;
    overflow: hidden;
    opacity: 0.85;
    transition: background-color 0.3s;
    transition: color 0.3s;
    transition: opacity 0.3s;
    text-decoration: none;
}

.gitlab:hover {
    color: #fca326;
    background-color: #548;
    opacity: 1;
    border: 1px solid black;
    box-shadow: 0px 10px 15px 5px #103c;
    -webkit-box-shadow: 0px 10px 15px 5px #103c;
    -moz-box-shadow: 0px 10px 15px 5px #103c;
}

.gitlab:hover img.color {
    opacity: 1;
}

.gitlab:hover img.gray {
    opacity: 0;
}

.gitlab div {
    margin-top: auto;
    margin-bottom: 30px;
    height: 2em;
    width: 2em;
}

.gitlab img {
    position: absolute;
    height: 40px;
    width: 40px;
}

.gitlab .color {
    opacity: 0;
}

.container {
    position: absolute;
    background: #0000;
    height: 200px;
    width: 200px;
    overflow: hidden;
}

.top-right {
    right: 0;
}

.bottom-right {
    right: 0;
    bottom: 0;
    transform: rotate(90deg);
}

.top-left {
    transform: rotate(-90deg);
}

.top-right {
    right: 0;
}

.bottom-right {
    right: 0;
    bottom: 0;
    transform: rotate(90deg);
}

.bottom-left {
    bottom: 0;
    transform: rotate(180deg);
}
</style>

<main>
    <div class="container {corner}">
        <a class="gitlab" href="{url}" target="_blank">
            <div>
                <img class="gray" alt="Gitlab" src="gitlab-logo-grey.svg" />
                <img class="color" alt="Gitlab" src="gitlab-logo.svg" />
            </div>
        </a>
    </div>
</main>

<script>
export let url = 'https://gitlab.com/'
export let corner

if (
    corner !== 'top-left' &&
    corner !== 'bottom-left' &&
    corner !== 'bottom-right'
)
    corner = 'top-right'
</script>
