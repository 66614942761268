<main>
    <button aria-label="Reset" on:click="{click}">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 256"
            aria-labelledby="title-reset">
            <title id="title-reset">Image to symbol resetting the game</title>
            <path
                d="M225.847 111.585c-.165.023-.326.054-.493.07-.397.04-.794.061-1.192.06h-47.995a12 12 0 0 1 0-24h19.03L181.74 74.26a76 76 0 1 0 0 107.48 12 12 0 0 1 16.97 16.971 100 100 0 1 1 0-141.422l13.457 13.456v-19.03a12 12 0 0 1 24 0v47.996c0 .397-.02.795-.06 1.191-.016.168-.048.33-.071.495-.032.223-.058.446-.102.667-.038.192-.091.38-.14.569-.046.19-.088.381-.146.57-.056.187-.127.368-.193.551-.066.186-.127.373-.203.556-.071.172-.155.336-.234.504-.088.188-.172.377-.27.56-.085.16-.182.31-.274.464-.108.182-.211.366-.33.543-.113.17-.24.329-.362.492-.112.15-.216.304-.336.45-.234.285-.482.557-.74.819l-.054.06c-.019.02-.04.034-.058.052-.262.259-.534.506-.819.74-.15.124-.309.232-.464.347-.158.118-.313.241-.477.351-.18.121-.368.226-.553.336-.151.09-.299.185-.454.268-.187.1-.379.185-.57.275-.164.077-.326.16-.494.23-.186.076-.376.138-.564.206-.18.064-.36.134-.545.19-.19.057-.381.1-.573.147-.188.048-.374.1-.566.139-.222.044-.445.07-.668.102z"
            ></path>
        </svg>
    </button>
</main>

<script>
import { createEventDispatcher } from 'svelte'

const dispatch = createEventDispatcher()
function click() {
    dispatch('click')
}
</script>
