<main>
    <button aria-label="{text}" on:click="{click}">
        {#if pause}
            <svg
                in:fade="{{ duration: 70 }}"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 256"
                aria-labelledby="title-continue">
                <title id="title-continue"
                    >Image to symbol continue the game</title>
                <path
                    d="M239.969 128a15.9 15.9 0 0 1-7.656 13.656l-143.97 87.985A15.998 15.998 0 0 1 64 215.992V40.008a15.998 15.998 0 0 1 24.344-13.649l143.969 87.985A15.9 15.9 0 0 1 239.969 128z"
                ></path>
            </svg>
        {:else}
            <svg
                in:fade="{{ duration: 70 }}"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 256"
                aria-labelledby="title-pause">
                <title id="title-pause">Image to symbol pause the game</title>
                <path
                    d="M216 48v160a16.018 16.018 0 0 1-16 16h-36a16.018 16.018 0 0 1-16-16V48a16.018 16.018 0 0 1 16-16h36a16.018 16.018 0 0 1 16 16zM92 32H56a16.018 16.018 0 0 0-16 16v160a16.018 16.018 0 0 0 16 16h36a16.018 16.018 0 0 0 16-16V48a16.018 16.018 0 0 0-16-16z"
                ></path>
            </svg>
        {/if}
    </button>
</main>

<script>
import { createEventDispatcher } from 'svelte'
import { fade } from 'svelte/transition'

const dispatch = createEventDispatcher()

export let pause = false

let text = 'Pause'

function click() {
    pause = !pause
    if (pause) {
        text = 'Play'
    } else {
        text = 'Pause'
    }

    dispatch('click', {
        pause: pause,
    })
}
</script>
