<main>
    <button
        aria-label="{text}"
        on:click="{click}"
        class="{drawing ? 'active' : ''}">
        {#if drawing}
            <svg
                in:fade="{{ duration: 70 }}"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 256"
                aria-labelledby="title-draw-stop">
                <title id="title-draw-stop"
                    >Image to symbol stop drawing cells into the game</title>
                <path
                    d="M216 204h-91.03l75.513-75.512.002-.003.003-.003 26.34-26.339a19.999 19.999 0 0 0 0-28.285L182.142 29.17a20.026 20.026 0 0 0-28.285.001l-120 120.001A19.872 19.872 0 0 0 28 163.313V208a20.022 20.022 0 0 0 20 20h168a12 12 0 0 0 0-24zM136 80.97 175.03 120 100 195.03 60.97 156zm32-32L207.03 88 192 103.03 152.97 64zm-116 132L75.03 204H52z"
                ></path>
            </svg>
        {:else}
            <svg
                in:fade="{{ duration: 70 }}"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 256"
                aria-labelledby="title-draw-start">
                <title id="title-draw-start"
                    >Image to symbol start drawing cells into the game</title>
                <path
                    d="m226.829 73.858-44.687-44.686a20 20 0 0 0-28.284 0l-26.342 26.342h-.001l-.001.002-91.999 91.998-.001.002-1.656 1.655A19.869 19.869 0 0 0 28 163.313V208a20.023 20.023 0 0 0 20 20h44.687a19.87 19.87 0 0 0 14.142-5.858l93.656-93.656.001-.002 26.343-26.342a20.024 20.024 0 0 0 0-28.284zM100 195.03 60.97 156 136 80.97 175.03 120zm-48-14.06L75.03 204H52zm140-77.94L152.97 64 168 48.97 207.03 88z"
                ></path>
            </svg>
        {/if}
    </button>
</main>

<script>
import { createEventDispatcher } from 'svelte'
import { fade } from 'svelte/transition'

const dispatch = createEventDispatcher()

export let drawing = false

let text = 'Start drawing'

function click() {
    drawing = !drawing
    if (drawing) {
        text = 'Start drawing'
    } else {
        text = 'Stop drawing'
    }

    dispatch('click', {
        drawing: drawing,
    })
}
</script>
